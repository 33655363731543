import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { adminLogin } from "../../actions/adminActions";
import { connect } from "react-redux";
import isEmail from "../../utils/isEmail";
import isEmpty from "../../utils/isEmpty";
import { useToasts } from "react-toast-notifications";

function AdminLogIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messages, setMessages] = useState("");
  const { addToast } = useToasts();

  useEffect(() => {
    localStorage.removeItem("admin_token");
  }, []);
  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.adminLoginSuccess) {
      setEmail("");
      setPassword("");
      setTimeout(() => {
        let url =
          props.messages.user && props.messages.user.adminType == 0
            ? "/adminHome"
            : "/technicianHome";
        props.history.push(url);
      }, 1000);
    }
  }, [props.messages]);

  useEffect(() => {
    if (messages && messages.adminLoginSuccess) {
      addToast(messages.adminLoginSuccess, {
        appearance: "success",
        autoDismiss: true,
      });
    } else if (messages && messages.adminLoginFailed) {
      addToast(messages.adminLoginFailed, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [messages]);

  const submit = () => {
    const data = {
      email,
      password,
    };
    props.adminLogin(data);
  };
  return (
    <div className="steps step1">
      <form>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={email}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              submit(e);
            }
          }}
          onChange={(event) => {
            const value = event.target.value;
            setEmail(value);
            if (isEmpty(value)) {
              messages.adminEmail = "Dieses Feld kann nicht leer sein";
            } else if (!isEmail(value)) {
              messages.adminEmail =
                "Dieses Feld sollte eine gültige E-Mail sein";
            } else {
              messages.adminEmail = null;
            }
          }}
          error={(messages && messages.adminEmail) > 0}
          helperText={messages && messages.adminEmail}
        />
        <TextField
          type="password"
          id="outlined-basic"
          label="Passwort"
          variant="outlined"
          value={password}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              submit(e);
            }
          }}
          onChange={(event) => {
            const value = event.target.value;
            setPassword(value);
            if (isEmpty(value)) {
              messages.adminPassword = "Dieses Feld kann nicht leer sein";
            } else {
              messages.adminPassword = null;
            }
          }}
          error={(messages && messages.adminPassword) > 0}
          helperText={messages && messages.adminPassword}
        />
        <p>
          <a href="/AdminForgetPassword"> Passwort vergessen </a>{" "}
        </p>
        <Button
          className="mainButton"
          onClick={(e) => {
            e.preventDefault();
            submit(e);
          }}
        >
          Anmelden
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  admin: state.admins.admin,
});

export default connect(mapStateToProps, { adminLogin })(AdminLogIn);
