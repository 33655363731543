import React, { Component } from 'react';
import classnames from 'classnames';

class TextFieldGroup extends Component {
    constructor() {
        super();

    }

    render() {
        const { type, message, placeholder, name, value, onChange, disabled, info, required, className, textLabel, textArea, rows, parentClassName, labelClassName, readOnly } = this.props;
        const classNames = classnames(className ? className : 'form-control form-control-lg', {
            'is-invalid': message
        })
        const parentClassNames = parentClassName ? parentClassName : 'form-group';
        return (
            <div className={parentClassNames}>
                {textLabel && <label htmlFor={name} className={labelClassName}>{textLabel}</label>}
                {textArea ?
                    <textarea
                        className={classNames}
                        rows={rows}
                        onChange={onChange}
                        name={name}
                        value={value}
                    />
                    :
                    <input
                        type={type}
                        className={classNames}
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        required={required}
                        readOnly={readOnly}
                    />
                }
                {info && <small className="form-text text-muted">{info}</small>}
                {message && <div className='invalid-feedback'> {message}</div>}

            </div>

        )
    }
}



export default TextFieldGroup;
