import React, { Component, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export default function Step4(props) {
  const [activeButton, changeState] = useState({
    activeObject: null,
    objects: [
      {
        id: 1,
        buttonTitle: "Sameday Reparatur ",
        serviceTime: "Bis 18 Uhr",
        servicePrice: "",
        value: 0,
      },
      {
        id: 2,
        buttonTitle: "Standard",
        serviceTime: "2-3 Tage",
        servicePrice: "",
        value: 1,
      },
      {
        id: 3,
        buttonTitle: "Sofort Reparatur",
        serviceTime: "1-2 Stunden ",
        servicePrice: "+19 Euro inkl. Mwst",
        value: 2,
      },
    ],
  });

  function toggleActive(index) {
    let selectedObject = activeButton.objects[index];
    changeState({ ...activeButton, activeObject: selectedObject });
    props.update("repair_time_type", selectedObject.value);
  }

  function activeButtonClass(index) {
    if (activeButton.objects[index] === activeButton.activeObject) {
      return "active";
    } else {
      return " ";
    }
  }

  return (
    <div className="steps step4">
      <Grid container spacing={1}>
        {activeButton.objects.map(
          ({ id, buttonTitle, serviceTime, servicePrice }, index) => {
            return (
              <div
                key={index}
                className={"choose" + " " + activeButtonClass(index)}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    toggleActive(index);
                  }}
                >
                  {buttonTitle}
                </a>
                <p> {serviceTime} </p>
                <p> {servicePrice}</p>
              </div>
            );
          }
        )}
      </Grid>
    </div>
  );
}
