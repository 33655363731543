import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import Button from "./Button";
import { getCustomers, setCustomer } from "../actions/customerActions";
import isEmpty from "../utils/isEmpty";

class SearchCustomer extends Component {
  constructor() {
    super();
    this.state = {
      companyName: "",
      messages: {},
      selectedCustomer: null,
    };
    this._onSubmit = this._onSubmit.bind(this);
  }

  componentWillMount() {
    localStorage.removeItem("current_user");
    this.setState({ messages: {} });
    this.props.getCustomers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.messages) {
      this.setState({ messages: nextProps.messages });
    }
  }

  _onSubmit(event) {
    event.preventDefault();
    const selectedCustomer = this.state.selectedCustomer;
    if (!isEmpty(selectedCustomer)) {
      this.props.setCustomer(selectedCustomer);
      setTimeout(() => {
        this.props.history.push("/steps");
      }, 1000);
    }
  }

  render() {
    const { customers } = this.props;
    return (
      <div className="register p-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto p-2 mt-5 border rounded-lg bg-light">
              <div className="lead text-center settings-title"></div>
              <form noValidate onSubmit={this._onSubmit}>
                <Autocomplete
                  id="companyName"
                  name="companyName"
                  options={customers}
                  getOptionLabel={(option) => option.customerNumber}
                  ref="companyName"
                  onChange={(event, value) => {
                    this.setState({ selectedCustomer: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kunde suchen"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Button
                  className={"btn btn-secondary btn-block mt-4"}
                  htmlText={"Weiter"}
                />
              </form>
              <Link className="text-center link-to-register" to="/register">
                zur Registrierung
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  customers: state.customer.customers,
});

export default connect(mapStateToProps, { getCustomers, setCustomer })(
  SearchCustomer
);
