import axios from "axios";
import {
  SET_DEPENDENT_REGISTER,
  GET_MESSAGES,
  GET_CURRENT_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  SET_CUSTOMER,
} from "./types";

export const setNewCustomer = (data) => (dispatch) => {
  axios
    .post("/api/customers/register", data)
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
      dispatch({
        type: SET_CUSTOMER,
        payload: res.data.customer,
      });
      // dispatch({
      //   type: GET_CURRENT_CUSTOMER,
      //   payload: data,
      // });
      // dispatch({
      //   type: SET_DEPENDENT_REGISTER,
      //   payload: true,
      // });
    })
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const getCustomers = () => (dispatch) => {
  axios
    .get(`/api/customers`)
    .then((res) => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_CUSTOMERS,
        payload: null,
      })
    );
};

export const setCustomer = (customer) => (dispatch) => {
  dispatch({
    type: GET_CURRENT_CUSTOMER,
    payload: customer,
  });
};

export const deleteCustomer = () => {
  return {
    type: DELETE_CUSTOMER,
    payload: {},
  };
};
