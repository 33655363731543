import React, { Component } from "react";
import { connect } from "react-redux";

import Button from "./Button";
import TextFieldGroup from "./TextFieldGroup";
import { setNewCustomer } from "./../actions/customerActions";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      customerNumber: "",
      customerName: "",
      companyName: "",
      contactPerson: "",
      address: "",
      messages: {},
    };
    this._onchange = this._onchange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  componentWillMount() {
    this.setState({ messages: {} });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.messages) {
      this.setState({ messages: nextProps.messages });
    }

    if (nextProps.messages.customerAdded) {
      this.setState({
        customerNumber: "",
        customerName: "",
        customerEmail: "",
        address: "",
      });
      setTimeout(() => {
        this.props.history.push("/steps");
      }, 2000);
    }
  }

  _onchange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _onSubmit(event) {
    event.preventDefault();
    const newCustomer = {
      customerNumber: this.state.customerNumber,
      customerName: this.state.customerName,
      customerEmail: this.state.customerEmail,
      address: this.state.address,
    };
    this.props.setNewCustomer(newCustomer);
  }

  render() {
    const { messages } = this.state;

    return (
      <div className="register p-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto p-2 mt-5 border rounded-lg bg-light">
              <div className="lead text-center settings-title">
                Registrieren
              </div>
              <form noValidate onSubmit={this._onSubmit}>
                {/* Customer Name */}
                <TextFieldGroup
                  placeholder={"Vor- und Nachname / Firma"}
                  name={"customerName"}
                  type={"text"}
                  value={this.state.customerName}
                  onChange={this._onchange}
                  message={messages.customerName}
                />
                {/* Address */}
                <TextFieldGroup
                  placeholder={"Ihre Adresse"}
                  name={"address"}
                  type={"text"}
                  value={this.state.address}
                  onChange={this._onchange}
                  message={messages.address}
                />
                {/* Customer Email */}
                <TextFieldGroup
                  placeholder={"Ihre E-Mail-Adresse"}
                  name={"customerEmail"}
                  type={"text"}
                  value={this.state.customerEmail}
                  onChange={this._onchange}
                  message={messages.customerEmail}
                />
                {/* Customer number */}
                <TextFieldGroup
                  placeholder={"Ihre Telefonnummer"}
                  name={"customerNumber"}
                  type={"text"}
                  value={this.state.customerNumber}
                  onChange={this._onchange}
                  message={messages.customerNumber}
                />
                <Button
                  className={"btn btn-secondary btn-block mt-4"}
                  htmlText={"Weiter"}
                />
                {messages.customerAdded && (
                  <div className="alert alert-success mt-2" role="alert">
                    {messages.customerAdded}
                  </div>
                )}
                {messages.customerNotAdded && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {messages.customerNotAdded}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps, { setNewCustomer })(Register);
