import axios from "axios";
import {
  GET_MESSAGES,
  GET_ORDERS,
  GET_CURRENT_ORDER,
  SET_ORDER_TO_DONE,
  DELETE_ORDER,
  SET_CUSTOMER,
  UPDATE_ORDER,
  GET_BRANDS,
  CREATE_OR_UPDATE_ORDER,
  REMOVE_ORDER,
} from "./types";

export const setNewOrder = (data) => (dispatch) => {
  axios
    .post("/api/orders/save", data)
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
      dispatch({
        type: SET_CUSTOMER,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      });
    });
};

export const addFile = (fileData, companyName, fileName) => (dispatch) => {
  axios
    .post(`/api/orders/upload/${companyName + "{" + fileName}`, fileData)
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const updateOrder = (data) => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .post("/api/orders/update", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data.message,
      });
      setTimeout(() => {
        dispatch({
          type: UPDATE_ORDER,
          payload: res.data.order,
        });
        dispatch({
          type: GET_MESSAGES,
          payload: {},
        });
      }, 1000);
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        dispatch({
          type: GET_MESSAGES,
          payload: err.response.data,
        });
      }
    });
};

export const updateFile = (fileData, companyName, fileName) => (dispatch) => {
  axios
    .post(`/api/orders/update/upload/${companyName + "{" + fileName}`, fileData)
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const getOrders = () => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .get(`/api/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ORDERS,
        payload: [],
      })
    );
};

export const getOrderById = (id) => (dispatch) => {
  axios
    .get(`/api/orders/id/${id}`)
    .then((res) => {
      dispatch({
        type: GET_CURRENT_ORDER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_CURRENT_ORDER,
        payload: null,
      })
    );
};

export const setOrderToDone = (id) => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .post(`/api/orders/done/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.order);
      dispatch({
        type: UPDATE_ORDER,
        payload: res.data.order,
      });
      // dispatch({
      //   type: SET_ORDER_TO_DONE,
      //   payload: id,
      // });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      });
    });
};

export const deleteOrder = (id) => (dispatch) => {
  axios
    .delete(`/api/orders/delete/${id}`)
    .then((res) =>
      dispatch({
        type: DELETE_ORDER,
        payload: id,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const addSparePart = (data) => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .post(`/api/parts/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data.message,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const deletePart = (id) => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .delete(`/api/parts/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) =>
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const getBrands = () => (dispatch) => {
  axios
    .get(`/api/brands`)
    .then((res) => {
      dispatch({
        type: GET_BRANDS,
        payload: res.data.brands,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const syncOrder = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ORDER,
    payload: data,
  });
};

export const createOrUpdateOrder = (data) => (dispatch) => {
  dispatch({
    type: CREATE_OR_UPDATE_ORDER,
    payload: data,
  });
};

export const removeFromOrders = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_ORDER,
    payload: data._id,
  });
};
