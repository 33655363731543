import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteCustomer } from "../actions/customerActions";
import { deleteMessages } from "../actions/messageActions";
import logo from "./../img/logo-handybausteine.png";
import isEmpty from "../utils/isEmpty";
import { useEffect } from "react";
import { useState } from "react";
function Navbar(props) {
  const [admin, setAdmin] = useState(null);
  const onClick = () => {
    // this.props.deleteCustomer();
    props.deleteMessages();
  };

  useEffect(() => {
    setAdmin(props.admin);
  }, [props.admin]);

  const links = (
    <div className="container">
      <img src={logo} alt="logo" className="image-logo" style={{    maxWidth: "30vw"}} ></img>

      {!isEmpty(admin) && admin.adminType == 0 && (
        <Link
          className="nav-link nav-no-mobile"
          to="/registerTechnician"
          onClick={() => onClick()}
        >
          Neuen Techniker anlegen
        </Link>
      )}
    </div>
  );
  return (
    <nav className="navbar navbar-expand-sm navbar-dark mb-4 company-color">
      {links}
    </nav>
  );
}

const mapStateToProps = (state) => ({
  admin: state.admins.admin,
});
export default connect(mapStateToProps, { deleteCustomer, deleteMessages })(
  Navbar
);
