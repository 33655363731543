import React, { useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { addAdmin } from "../../actions/adminActions";
import { useEffect } from "react";
import isEmail from "../../utils/isEmail";
import isEmpty from "../../utils/isEmpty";
function AdminRegister(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messages, setMessages] = useState({});

  const submit = () => {
    const admin = {
      adminEmail: email,
      password: password,
      adminName: name,
    };
    props.addAdmin(admin);
  };

  useEffect(() => {
    if (!props.admin || props.admin.adminType != 0) {
      props.history.push("/technicianHome");
    }
  }, [props.admin]);

  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.adminAdded) {
      setName("");
      setEmail("");
      setConfirmEmail("");
      setPassword("");
      props.history.push("/adminHome");
    }
  }, [props.messages]);
  return (
    <div className="steps step1">
      <form>
        <TextField
          type="name"
          id="outlined-basic"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event) => {
            const value = event.target.value;
            setName(value);
            if (isEmpty(value)) {
              messages.adminName = "Dieses Feld kann nicht leer sein";
            } else {
              messages.adminName = null;
            }
          }}
          error={messages && messages.adminName}
          helperText={messages && messages.adminName}
        />
        <TextField
          type="email"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => {
            const value = event.target.value;
            setEmail(value);
            if (isEmpty(value)) {
              messages.adminEmail = "Dieses Feld kann nicht leer sein";
            } else if (!isEmail(value)) {
              messages.adminEmail =
                "Dieses Feld sollte eine gültige E-Mail sein";
            } else {
              messages.adminEmail = null;
            }
          }}
          error={messages && messages.adminEmail}
          helperText={messages && messages.adminEmail}
        />
        <TextField
          type="email"
          id="outlined-basic"
          label="Email bestätigen "
          variant="outlined"
          value={confirmEmail}
          onChange={(event) => {
            const value = event.target.value;
            setConfirmEmail(value);
            if (email != value) {
              messages.adminConfirmEmail =
                "Dieses Feld sollte mit E-Mail identisch sein";
            } else {
              messages.adminConfirmEmail = null;
            }
          }}
          error={messages && messages.adminConfirmEmail}
          helperText={messages && messages.adminConfirmEmail}
        />
        <TextField
          type="password"
          id="outlined-basic"
          label="Passwort"
          variant="outlined"
          value={password}
          onChange={(event) => {
            const value = event.target.value;
            setPassword(value);
            if (isEmpty(value)) {
              messages.password = "Dieses Feld kann nicht leer sein";
            } else {
              messages.password = null;
            }
          }}
          error={props.messages && props.messages.password}
          helperText={props.messages && props.messages.password}
        />
        <Button className="mainButton" onClick={submit}>
          Techniker anlegen
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  admin: state.admins.admin,
});

export default connect(mapStateToProps, { addAdmin })(AdminRegister);
