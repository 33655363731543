import React, { Component, useState, useEffect } from "react";
import { getLoggedAdmin, getTechnicians } from "../../actions/adminActions";
import { getCustomers } from "../../actions/customerActions";
import {
  getOrders,
  getBrands,
  createOrUpdateOrder,
  removeFromOrders,
} from "../../actions/orderActions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import EditJob from "../../components/admin/EditJobTechnician";
import AddSpareParts from "../../components/admin/AddSpareParts";
import Orders from "../../components/admin/Orders";
import ViewInfo from "../../components/admin/ViewInfo";

import io from "socket.io-client";
import isEmpty from "../../utils/isEmpty";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function AdminHome(props) {
  const [messages, setMessages] = useState("");
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  const [filterTechnician, setFilterTechnician] = useState("");
  const [filterBrand, setfilterBrand] = useState("");

  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedParts, setSelectedParts] = useState({});
  const [selectedOrderInfo, setSelectedOrderInfo] = useState({});

  const [openEdit, setOpenEdit] = useState(false);
  const [openSparePart, setOpenSparePart] = useState(false);
  const [openViewInfo, setOpenViewInfo] = useState(false);

  let history = useHistory();
  useEffect(() => {
    props.getLoggedAdmin();
    props.getTechnicians();
    props.getCustomers();
    props.getBrands();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.admin)) {
      connectToSocket();
    }
  }, [props.admin]);

  const connectToSocket = () => {
    try {
      // const socket = io("http://localhost:8200", { secure: true });
      const socket = io.connect();
      socket.on("update", (data) => {
        const { updated_order, updated_by } = data;
        if (props.admin) {
          if (!updated_order.technician) {
            props.removeFromOrders(updated_order);
          } else if (
            updated_by._id != props.admin._id &&
            updated_order.technician &&
            updated_order.technician._id == props.admin._id
          ) {
            props.createOrUpdateOrder(updated_order);
          } else if (
            updated_order.technician &&
            updated_order.technician._id != props.admin._id
          ) {
            props.removeFromOrders(updated_order);
          }
        }
      });
    } catch (err) {
      console.log("connection error", err);
    }
  };

  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.adminMeError) {
      localStorage.removeItem("admin_token");
      history.push("/");
    } else if (props.messages && props.messages.adminMeSucess) {
      props.getOrders();
    }
  }, [props.messages]);

  useEffect(() => {
    setOrders(props.orders);
    setAllOrders(props.orders);
  }, [props.orders]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenEdit = (order) => {
    setSelectedParts({});
    setOpenSparePart(false);
    setSelectedEdit(order);
    setOpenEdit(true);
  };

  const handleClose = (order) => {
    setSelectedParts({});
    setOpenSparePart(false);
    setSelectedEdit({});
    setOpenEdit(false);
  };

  const handleOpenSpare = (order) => {
    setOpenEdit(false);
    setSelectedEdit({});
    setSelectedParts(order);
    setOpenSparePart(true);
  };

  const handleOpenInfo = (order) => {
    setSelectedOrderInfo(order);
    setOpenViewInfo(true);
  };

  const handleCloseViewInfo = () => {
    setSelectedOrderInfo({});
    setOpenViewInfo(false);
  };

  useEffect(() => {
    let ordersClone = [...allOrders];
    if (ordersClone && ordersClone.length > 0) {
      if (filterTechnician) {
        ordersClone = ordersClone.filter((orderClone) => {
          if (orderClone.technician) {
            return orderClone.technician.adminName
              .toLowerCase()
              .includes(filterTechnician.toLowerCase());
          } else return false;
        });
      }
      if (filterBrand) {
        ordersClone = ordersClone.filter((orderClone) => {
          return orderClone.device.toLowerCase() === filterBrand.toLowerCase();
        });
      }
      setOrders(ordersClone);
    } else {
      setOrders(allOrders);
    }
  }, [filterBrand, filterTechnician]);
  return (
    <div className="AdminHome">
      <AppBar position="static" color="default">
        {props.admin && props.admin.adminType == 0 && (
          <div>
            <TextField
              fullWidth
              placeholder="Filtern nach Mitarbeiter"
              name="query"
              variant="outlined"
              style={{ marginBottom: 20 }}
              onChange={(event) => {
                setFilterTechnician(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Autocomplete
              options={props.brands}
              getOptionLabel={(option) => option.brand}
              onChange={(event, value) => {
                let query = value ? value.code : "";
                setfilterBrand(query);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gerät"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
        )}
        {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Offen" {...a11yProps(0)} />
          <Tab label="In Bearbeitung" {...a11yProps(1)} />
          {props.admin && props.admin.adminType == 0 && (
            <Tab label="Benötigt Ersatzteile" {...a11yProps(2)} />
          )}
          <Tab label="Fertig" {...a11yProps(3)} />
        </Tabs> */}

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Offen" {...a11yProps(0)} />
          <Tab label="In Bearbeitung" {...a11yProps(1)} />
          <Tab label="Abgeschlossen" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Orders
          orders={orders.filter((order) => {
            return order.technicianStatus == 0;
          })}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
          isTechnician={true}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Orders
          orders={orders.filter((order) => {
            return order.technicianStatus == 1;
          })}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
          isTechnician={true}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Orders
          orders={orders.filter((order) => order.technicianStatus == 2)}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleOpenInfo={handleOpenInfo}
          handleClose={handleClose}
          isTechnician={true}
        />
      </TabPanel>
      {selectedEdit && (
        <EditJob
          handleClose={() => {
            setOpenEdit(false);
          }}
          open={openEdit}
          order={selectedEdit}
        />
      )}
      {openSparePart && (
        <AddSpareParts
          handleClose={() => {
            setOpenSparePart(false);
          }}
          open={openSparePart}
          order={selectedParts}
        />
      )}
      {openViewInfo && (
        <ViewInfo
          open={openViewInfo}
          order={selectedOrderInfo}
          handleClose={handleCloseViewInfo}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  admin: state.admins.admin,
  orders: state.orders.orders,
  brands: state.orders.brands,
});

export default connect(mapStateToProps, {
  getLoggedAdmin,
  getOrders,
  getTechnicians,
  getCustomers,
  getBrands,
  createOrUpdateOrder,
  removeFromOrders,
})(AdminHome);
