import { combineReducers } from "redux";
import customerReducer from "./customerReducer";
import messageReducer from "./messageReducer";
import orderReducer from "./orderReducer";
import adminReducer from "./adminReducer";

export default combineReducers({
  customer: customerReducer,
  messages: messageReducer,
  orders: orderReducer,
  admins: adminReducer,
});
