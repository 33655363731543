import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { resetAdminPassword } from "../../actions/adminActions";
import isEmail from "../../utils/isEmail";
import isEmpty from "../../utils/isEmpty";
import { useHistory } from "react-router-dom";

function AdminForgetPassword(props) {
  const [messages, setMessages] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  let history = useHistory();
  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.emailSent) {
      setEmail("");
      setConfirmEmail("");
      setTimeout(() => {
        history.push("/");
      }, 1500);
    }
  }, [props.messages]);
  return (
    <div className="steps step1">
      {messages.emailSent && (
        <div className="alert alert-success mt-2" role="alert">
          {messages.emailSent}
        </div>
      )}
      <form>
        <TextField
          type="email"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => {
            const value = event.target.value;
            setEmail(value);
            if (isEmpty(value)) {
              messages.adminEmail = "Dieses Feld kann nicht leer sein";
            } else if (!isEmail(value)) {
              messages.adminEmail =
                "Dieses Feld sollte eine gültige E-Mail sein";
            } else {
              messages.adminEmail = null;
            }
          }}
          error={messages && messages.adminEmail}
          helperText={messages && messages.adminEmail}
        />
        <TextField
          type="email"
          id="outlined-basic"
          label="Email bestätigen "
          variant="outlined"
          value={confirmEmail}
          onChange={(event) => {
            const value = event.target.value;
            setConfirmEmail(value);
            if (email != value) {
              messages.adminConfirmEmail =
                "Dieses Feld sollte mit E-Mail identisch sein";
            } else {
              messages.adminConfirmEmail = null;
            }
          }}
          error={messages && messages.adminConfirmEmail}
          helperText={messages && messages.adminConfirmEmail}
        />
        <Button
          className="mainButton"
          onClick={() => {
            if (email && confirmEmail && email === confirmEmail) {
              props.resetAdminPassword({ email: email });
            }
          }}
          disabled={!(email && confirmEmail && email === confirmEmail)}

        >
          Passwort zurück setzen
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps, {
  resetAdminPassword,
})(AdminForgetPassword);
