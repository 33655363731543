import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from "react-redux";
import moment from "moment";
import { updateOrder } from "../../actions/orderActions";
// import Checkbox from "@material-ui/core/Checkbox";

const statuses = [
  { title: "Offen", value: 0 }, // open
  { title: "Benötigt Ersatzteile", value: 1 }, // Requires spare parts
  { title: "Platinenreperatur", value: 2 }, // Circuit board repair
  { title: "Standardreperatur", value: 3 }, // Standard repair
  { title: "Reperatur abgeschlossen", value: 4 }, // Repair completed
  { title: "Abgeschlossen", value: 5 }, // Completed
];

const repairTimeOptions = [
  { label: "Sameday Reparatur", value: 0 },
  { label: "Standard", value: 1 },
  { label: "Sofort Reparatur", value: 2 },
];
function EditJob(props) {
  const [order, setOrder] = useState({});
  const [messages, setMessages] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [selectedTechnician, setSelectedTechnician] = useState("");
  const { currentAdmin } = props;

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    setMessages(props.messages);
  }, [props.messages]);

  useEffect(() => {
    if (messages && messages.orderUpdated) {
      setOpenSnackBar(true);
      setSnackBarMessage(messages.orderUpdated);
    }
  }, [messages]);

  const submit = () => {
    const newOrder = {
      id: order._id,
      device: order.device,
      model: order.model,
      mobileNumber: order.mobileNumber,
      problems: order.problems,
      opened_before: order.opened_before,
      fluid_damage: order.fluid_damage,
      replace_battery: order.replace_battery,
      repair_time_type: order.repair_time_type,
      summary: order.summary,
      price: order.price,
      deliveryDate: order.deliveryDate,
      technician: order.technician,
      notice: order.notice,
      status: order.status,
      technicianStatus: order.technicianStatus,
      repair_type: order.repair_type,
    };
    if ((newOrder.status === 2 || newOrder.status === 3) && !order.technician) {
      alert("Bitte wählen Sie zuerst einen Techniker aus");
    } else {
      props.updateOrder(newOrder);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newOrder = { ...order };
    newOrder[name] = value;
    setOrder(newOrder);
    if (name === "technician") {
      setSelectedTechnician(value);
    }
  };

  const handleTechnicianChange = (event) => {
    const { name, value } = event.target;
    const newOrder = { ...order };
    if (value) {
      newOrder[name] = value;
    } else {
      newOrder[name] = null;
    }
    setOrder(newOrder);
    setSelectedTechnician(value);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      const newOrder = { ...order };
      newOrder[name] = value;
      setOrder(newOrder);
    } else {
      alert("Bitte wählen Sie einen gültigen Wert");
    }
  };

  const handleStatusChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      const newOrder = { ...order };
      newOrder[name] = value;
      console.log(value);
      if (value === 2) {
        newOrder.technicianStatus = 0;
        newOrder.repair_type = 2;
      } else if (value === 3) {
        newOrder.technicianStatus = 0;
        newOrder.repair_type = 3;
      }
      setOrder(newOrder);
    } else {
      alert("Bitte wählen Sie einen gültigen Wert");
    }
  };

  const getProblems = (problems) => {
    if (problems) {
      try {
        problems = JSON.parse(problems);
        problems = Object.keys(problems).map((problem) => {
          if (problem === "extra") {
            return problems["extra"];
          }
          return problem;
        });
        return problems.join(" , ");
      } catch (err) {
        console.log(err);
      }
    }

    return "";
  };
  const handleClose = () => {
    setSelectedTechnician("");
    props.handleClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
        className="steps"
      >
        <DialogTitle id="alert-dialog-title">
          Auftrag bearbeiten
          <Button onClick={handleClose} className="closeModal" color="primary">
            Zurück
          </Button>
        </DialogTitle>
        <DialogContent>
          <div>
            <form>
              <FormControl variant="outlined">
                <TextField
                  label="Kundenname"
                  variant="outlined"
                  fullWidth
                  value={order.customer && order.customer.customerName}
                  disabled={true}
                  className="formMargin"
                />
              </FormControl>
              {order && order.keyCode_type === "0" && (
                <FormControl variant="outlined">
                  <TextField
                    label="Kundenname"
                    variant="outlined"
                    fullWidth
                    value={order.keyCode}
                    disabled={true}
                    className="formMargin"
                  />
                </FormControl>
              )}
              {order && order.keyCode_type === 1 && (
                <a href={order.keyCode} target="_blank">
                  Tastensperrcode
                </a>
              )}
              <FormControl variant="outlined">
                <TextField
                  label="Erstellungsdatum"
                  variant="outlined"
                  fullWidth
                  value={moment(order.createdAt).format("DD-MM-YYYY HH:mm")}
                  disabled={true}
                  className="formMargin"
                />
              </FormControl>
              <FormControl variant="outlined">
                <TextField
                  label="Probleme"
                  variant="outlined"
                  fullWidth
                  value={getProblems(order.problems)}
                  disabled={true}
                  className="formMargin"
                />
              </FormControl>
            </form>
          </div>
          <form>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Techniker auswählen
              </InputLabel>
              <Select
                native
                label="Techniker auswählen"
                inputProps={{
                  name: "technician",
                  shrink: "true",
                }}
                onChange={handleTechnicianChange}
                value={
                  order.technician && order.technician._id
                    ? order.technician._id
                    : selectedTechnician
                }
              >
                <option aria-label="None" value="" />
                {props.technicians.map((technician) => (
                  <option value={technician._id} key={technician._id}>
                    {technician.adminName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Lieferzeit
              </InputLabel>
              <Select
                native
                label="Lieferzeit"
                inputProps={{
                  name: "repair_time_type",
                }}
                onChange={handleSelectChange}
                value={order.repair_time_type}
              >
                <option aria-label="None" value="" />
                {repairTimeOptions.map((option) => (
                  <option
                    value={option.value}
                    key={"time_option" + option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Status ändern
              </InputLabel>
              <Select
                native
                label="Status ändern"
                inputProps={{
                  name: "status",
                }}
                onChange={handleStatusChange}
                value={order.status}
              >
                <option aria-label="None" value="" />
                {statuses.map((status) => (
                  <option value={status.value} key={"status_" + status.value}>
                    {status.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Lieferdatum"
              variant="outlined"
              type="datetime-local"
              name="deliveryDate"
              onChange={handleChange}
              value={order.deliveryDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-basic"
              label="Preis "
              variant="outlined"
              name="price"
              onChange={handleChange}
              value={order.price}
            />
            <TextareaAutosize
              rowsMin={3}
              placeholder="Notizen"
              name="notice"
              onChange={handleChange}
              value={order.notice}
            />
          </form>
        </DialogContent>
        <DialogActions className="actionButtons">
          <Button className="mainButton" onClick={submit}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackBar(false);
          setSnackBarMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackBar(false);
            setSnackBarMessage("");
          }}
          severity="success"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  technicians: state.admins.technicians,
  currentAdmin: state.admins.admin,
  customers: state.customer.customers,
  brands: state.orders.brands,
});

export default connect(mapStateToProps, { updateOrder })(EditJob);
