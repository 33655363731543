import React, { Component } from 'react';

class Button extends Component {
    constructor() {
        super();
    }

    _renderIcon(iconName) {
        return (
            <i className="material-icons" >{iconName}</i>
        )
    }

    render() {
        const { className, style, htmlText, onClick, icon, name } = this.props;

        return (
            <button
                className={className}
                style={{ style }}
                onClick={onClick}
                name={name}
            >
                {htmlText}
                {icon && this._renderIcon(icon)}
            </button>

        )
    }
}

export default Button;
