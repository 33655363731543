import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import "./../css/App.css";
import "./../sass/style.css";
import Navbar from "./Navbar";
import Register from "./Register";
import Steps from "../pages/customer/Steps";

import AdminHome from "../pages/admin/AdminHome";
import AdminLogIn from "../pages/admin/AdminLogIn";
import AdminRegister from "../pages/admin/AdminRegister";
import AdminForgetPassword from "../pages/admin/AdminForgetPassword";

import TechnicalHome from "../pages/technical/TechnicalHome";
import TechnicalLogin from "../pages/technical/TechnicalLogin";
import TechnicalRegister from "../pages/technical/TechnicalRegister";
import TechnicalForgetPassword from "../pages/technical/TechnicalForgetPassword";

import Footer from "./Footer";
import SearchCustomer from "./SearchCustomer";
import store from "./../store";

import { ToastProvider } from "react-toast-notifications";

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <Router>
          <div className="App">
            <Navbar />
            <div className="container">
              <Route exact path="/" component={AdminLogIn} />
              <Route exact path="/admin" component={AdminLogIn} />
              <Route exact path="/technician" component={TechnicalLogin} />
              <Route exact path="/customer" component={SearchCustomer} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/steps" component={Steps} />
              <Route exact path="/adminLogIn" component={AdminLogIn} />
              <Route
                exact
                path="/registerTechnician"
                component={AdminRegister}
              />
              <Route path="/adminHome" component={AdminHome} />
              <Route path="/technicianHome" component={TechnicalHome} />
              <Route
                exact
                path="/adminForgetPassword"
                component={AdminForgetPassword}
              />
            </div>
          </div>
          <div className="clear"></div>
          <Footer />
        </Router>
      </ToastProvider>
    </Provider>
  );
}

export default App;
