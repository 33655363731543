import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { getBrands } from "../../actions/orderActions";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

function Step1(props) {
  const keyCodePad = useRef(null);
  const stageCanvasRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [SignutureWidth, setSignutureWidth] = useState([]);

  useEffect(() => {
    if (stageCanvasRef.current) {
      let width = stageCanvasRef.current.offsetWidth;
      setSignutureWidth(width);
    }
  }, [stageCanvasRef]);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleChange = (event) => {
    props.update(event.target.name, event.target.value);
  };

  const handleCheckbox = (event) => {
    props.update(event.target.name, event.target.checked);
  };

  useEffect(() => {
    props.getBrands();
  }, []);

  return (
    <div className="steps step1">
      <form>
        <Autocomplete
          name="device"
          options={props.brands}
          getOptionLabel={(option) => option.brand}
          onChange={(event, value) => {
            props.update("device", value ? value.code : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Gerät"
              variant="outlined"
              value={props.device}
              fullWidth
            />
          )}
        />
        <TextField
          id="outlined-basic"
          label="Modell"
          name="model"
          variant="outlined"
          onChange={handleChange}
          value={props.model}
        />
        <TextField
          type="number"
          id="outlined-basic"
          label="Telefonnummer unter der sie erreichbar sind"
          name="mobileNumber"
          variant="outlined"
          onChange={handleChange}
          value={props.mobileNumber}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Tastensperrcode</FormLabel>
          <RadioGroup
            aria-label="tastensperrcode"
            name="keyCode_type"
            value={props.keyCode_type}
            onChange={(event) => {
              props.updateKeyCodeType(event.target.value);
            }}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="Passwort"
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Tastensperrmuster"
            />
          </RadioGroup>
        </FormControl>
        {props.keyCode_type == 0 && (
          <TextField
            label="Tastensperrcode"
            name="keyCode"
            variant="outlined"
            onChange={handleChange}
            value={props.keyCode}
          />
        )}
        {props.keyCode_type == 1 && (
          <React.Fragment>
            <h5> Tastensperrcode </h5>
            <section className="signuture" ref={stageCanvasRef}>
              <SignatureCanvas
                penColor="black"
                ref={keyCodePad}
                onEnd={(data) => {
                  props.update(
                    "keyCode",
                    keyCodePad.current.getTrimmedCanvas().toDataURL("image/png")
                  );
                }}
                canvasProps={{
                  width: SignutureWidth,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            </section>
            <Button
              disabled={!props.keyCode}
              onClick={() => {
                keyCodePad.current.clear();
                props.update("keyCode", undefined);
              }}
            >
              Tastensperrmuster löschen
            </Button>
          </React.Fragment>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                name="agreement"
                checked={props.agreement}
                color="primary"
                onChange={handleCheckbox}
              />
            }
          />
          <p style={{ marginTop: 5 }}>
            {" "}
            Ich habe die Hinweise zum{" "}
            <a onClick={handleClickOpen} href="#">
              {" "}
              Datenschutz{" "}
            </a>{" "}
            gelesen und akzeptiere diese.{" "}
          </p>
        </div>
      </form>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          Terms and Conditions{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            component={"span"}
            variant={"body2"}
          >
            <ul>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
              <li>
                {" "}
                Terms and Conditions Terms and Conditions Terms and Conditions{" "}
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.update("agreement", true);
              setOpen(false);
            }}
            color="primary"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  brands: state.orders.brands,
});

export default connect(mapStateToProps, { getBrands })(Step1);
