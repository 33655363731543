import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import isEmpty from "../../utils/isEmpty";
import { connect } from "react-redux";
import { setNewOrder } from "../../actions/orderActions";
import { useHistory } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Geräte-Informationen",
    "Fehler",
    "Weitere Fragen",
    "Wie schnell soll es gehen?",
    "Abschluss",
  ];
}

function Steps(props) {
  let history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [messages, setMessages] = React.useState([]);
  const [order, setOrder] = React.useState({
    device: "",
    model: "",
    keyCode: "",
    keyCode_type: "0",
    mobileNumber: "",
    agreement: false,
    problems: {},
    opened_before: "",
    fluid_damage: "",
    replace_battery: "",
    repair_time_type: "",
    summary: "",
    instruction_agreement: false,
  });

  useEffect(() => {
    if (isEmpty(props.customer)) {
      props.history.push("/customer");
      // window.location.href = "/customers";
    }
    setMessages(props.messages);
    if (props.messages && props.messages.newOrder) {
      reset();
      setActiveStep(steps.length);
    } else if (props.messages && props.messages.orderFailed) {
      setActiveStep(steps.length);
    }
  }, [props.messages]);

  const steps = getSteps();
  const update = (key, value) => {
    const newOrder = { ...order };
    newOrder[key] = value;
    setOrder(newOrder);
  };

  const updateKeyCodeType = (value) => {
    const newOrder = { ...order };
    newOrder.keyCode_type = value;
    newOrder.keyCode = "";
    setOrder(newOrder);
  };

  const saveOrder = () => {
    const newOrder = { ...order };
    newOrder.customerId = props.customer._id;
    newOrder.problems = JSON.stringify(order.problems);
    props.setNewOrder(newOrder);
  };

  const reset = () => {
    const emptyOrder = {
      device: "",
      model: "",
      keyCode: "",
      keyCode_type: "0",
      mobileNumber: "",
      agreement: false,
      problems: {},
      opened_before: "",
      fluid_damage: "",
      replace_battery: "",
      repair_time_type: "",
      signature: "",
    };
    setOrder(emptyOrder);
    setActiveStep(0);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            {...order}
            update={update}
            updateKeyCodeType={updateKeyCodeType}
          />
        );
      case 1:
        return <Step2 {...order} update={update} />;
      case 2:
        return <Step3 {...order} update={update} />;
      case 3:
        return <Step4 {...order} update={update} />;
      case 4:
        return <Step5 {...order} update={update} />;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    if (!isNextDisabled()) {
      if (activeStep == steps.length - 1) {
        saveOrder();
      } else if (activeStep <= steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isNextDisabled = () => {
    const {
      device,
      model,
      keyCode,
      keyCode_type,
      mobileNumber,
      agreement,
      problems,
      opened_before,
      fluid_damage,
      replace_battery,
      repair_time_type,
      instruction_agreement,
      signature,
    } = order;
    if (activeStep == 0) {
      return (
        isEmpty(device) || isEmpty(model) || isEmpty(keyCode) || !agreement
      );
    } else if (activeStep == 1) {
      return Object.keys(problems).length <= 0;
    } else if (activeStep == 2) {
      return (
        isEmpty(opened_before) ||
        isEmpty(fluid_damage) ||
        isEmpty(replace_battery)
      );
    } else if (activeStep == 3) {
      return isEmpty(repair_time_type);
    } else if (activeStep == 4) {
      return !instruction_agreement || isEmpty(signature);
    }
    // return true ;
  };
  return (
    <React.Fragment>
      <div className="steps step1">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              {messages.newOrder && (
                <div className="alert alert-success mt-2" role="alert">
                  {messages.newOrder}
                </div>
              )}
              {messages.orderFailed && (
                <div className="alert alert-danger mt-2" role="alert">
                  {messages.orderFailed}
                </div>
              )}
              <Button
                onClick={() => {
                  reset();
                  history.go("/customer");
                  // props.history.push("/customer");
                }}
              >
                Zum Kunden-Dashboard
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions} component={"span"}>
                {getStepContent(activeStep)}
              </Typography>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  className="mainButton"
                  disabled={isNextDisabled()}
                >
                  {activeStep === steps.length - 1 ? "Fertig" : "Weiter"}
                </Button>
                {activeStep != steps.length && (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Zurück
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  customer: state.customer.customer,
});

// export default Steps;
export default connect(mapStateToProps, { setNewOrder })(Steps);
