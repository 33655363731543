import React from "react";
import Order from "./Order";
import OrderTechnician from "./OrderTechnician";
import { useEffect } from "react";
export default function Orders(props) {
  const {
    orders,
    handleOpenEdit,
    handleOpenSpare,
    handleClose,
    handleOpenInfo,
    isTechnician,
  } = props;
  const listOrders = () => {
    return orders.map((order) => {
      return isTechnician ? (
        <OrderTechnician
          order={order}
          key={order._id}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
        />
      ) : (
        <Order
          order={order}
          key={order._id}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
        />
      );
    });
  };
  return (
    <div>
      {orders && orders.length > 0 ? listOrders() : <h4>Keine Aufträge</h4>}
    </div>
  );
}
