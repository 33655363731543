export const GET_MESSAGES = "GET_MESSAGES";
export const DELETE_MESSAGES = "DELETE_MESSAGES";
export const GET_CURRENT_CUSTOMER = "GET_CURRENT_CUSTOMER";
export const SET_DEPENDENT_REGISTER = "SET_DEPENDENT_REGISTER";
export const SET_DEPENDENT_SEARCH = "SET_DEPENDENT_SEARCH";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

// Orders
export const GET_ORDERS = "GET_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_CURRENT_ORDER = "GET_CURRENT_ORDER";
export const SET_ORDER_TO_DONE = "SET_ORDER_TO_DONE";
export const DELETE_ORDER = "DELETE_ORDER";
export const GET_BRANDS = "GET_BRANDS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const CREATE_OR_UPDATE_ORDER = "CREATE_OR_UPDATE_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";

// Admins
export const GET_CURRENT_ADMIN = "GET_CURRENT_ADMIN";
export const GET_ADMINS = "GET_ADMINS";
export const SET_ADMIN = "SET_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const SET_TECHNICIANS = "SET_TECHNICIANS";
