import { GET_MESSAGES, DELETE_MESSAGES } from '../actions/types';

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGES:
            return action.payload
        default:
            return state;
        case DELETE_MESSAGES:
            return action.payload

    };
}