import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { addSparePart } from "../../actions/orderActions";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

function AddSpareParts(props) {
  const [data, setData] = useState(false);
  const [messages, setMessages] = useState(false);
  const [order, setOrder] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  let history = useHistory();

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newItem = { ...data };
    newItem[name] = value;
    setData(newItem);
  };

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.newSpareParts) {
      setTimeout(() => {
        history.go();
      }, 1500);
    }
  }, [props.messages]);

  useEffect(() => {
    if (messages && messages.newSpareParts) {
      setOpenSnackBar(true);
      setSnackBarMessage(messages.newSpareParts);
    }
  }, [messages]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      className="steps"
    >
      <DialogTitle id="alert-dialog-title">
        Ersatzteile
        <Button
          onClick={props.handleClose}
          class="closeModal"
          color="primary"

        >
          Zurück
        </Button>
      </DialogTitle>

      <DialogContent>
        <form>
          <TextField
            id="brand"
            label="Marke"
            type="text"
            name="brand"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
          <TextField
            id="Modal"
            label="Modell"
            type="text"
            name="model"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
          <TextField
            id="spareParts"
            label="Ersatzteil"
            type="text"
            name="parts"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
          <TextField
            id="numbers"
            label="Stückzahl"
            type="number"
            defaultValue="2017-05-24T10:30"
            InputLabelProps={{
              shrink: true,
            }}
            name="number"
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions className="actionButtons">
        <Button
          className="mainButton"

          onClick={() => {
            const spareParts = {
              brand: data.brand,
              model: data.model,
              deliveryDate: data.deliveryDate,
              number: data.number,
              parts: data.parts,
              order: order._id,
            };
            console.log(spareParts);
            props.addSparePart(spareParts);
          }}
        >
          Speichern
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackBar(false);
          setSnackBarMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackBar(false);
            setSnackBarMessage("");
          }}
          severity="success"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});
export default connect(mapStateToProps, {
  addSparePart,
})(AddSpareParts);
