import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ImageViewer from "../ImageViewer";
import { useState } from "react";
function ViewCustomer(props) {
  const { order } = props;
  const getDileveryTime = (option) => {
    if (option == 0) {
      return "Sameday Reparatur";
    } else if (option == 1) {
      return "Standard";
    } else if (option == 2) {
      return "Sofort Reparatur";
    }
    return "";
  };

  const getProblems = (problems) => {
    if (problems) {
      try {
        problems = JSON.parse(problems);
        problems = Object.keys(problems).map((problem) => {
          if (problem == "extra") {
            return problems["extra"];
          }
          return problem;
        });
        return problems.join(" , ");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getBrand = (code) => {
    if (props.brands && props.brands.length > 0) {
      const brand = props.brands.find((brand) => brand.code == code);
      return brand ? brand.brand : "";
    }
  };

  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      className="steps"
    >
      <DialogTitle id="alert-dialog-title">
        Kundeninformation
        <Button
          onClick={props.handleClose}
          className="closeModal"
          color="primary"
        >
          Zurück
        </Button>
      </DialogTitle>
      <DialogContent>
        {order && (
          <form>
            <TextField
              label="Kundenname"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={order.customer && order.customer.customerName}
              disabled={true}
            />
            <TextField
              label="Telefonnummer"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={order.customer && order.customer.customerNumber}
              disabled={true}
            />
            <TextField
              label="Kunden-eMail"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={order.customer && order.customer.customerEmail}
              disabled={true}
            />
            <TextField
              label="Adresse"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={order.customer && order.customer.address}
              disabled={true}
            />

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" style={{}}>
                  Fehler
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  Problem: {getProblems(order.problems)}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" style={{}}>
                  Geräte-Informationen
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography variant="subtitle1">
                      Gerät: {getBrand(order.device)}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      Modell: {order.model}
                    </Typography>
                  </li>
                  {order.keyCode_type == 0 && (
                    <li>
                      <Typography variant="subtitle1">
                        Tastensperrcode: {order.keyCode}
                      </Typography>
                    </li>
                  )}
                  {order.keyCode_type == 1 && (
                    <li>
                      <a href={order.keyCode} target="_blank" download>
                        Tastensperrcode
                      </a>
                    </li>
                  )}
                  <li>
                    <Typography variant="subtitle1">
                      Telefonnummer: {order.mobileNumber}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      Unterschrift{" "}
                      <Button>
                        <a href={order.signature} target="_blank" download>
                          herunterladen
                        </a>
                      </Button>
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" style={{}}>
                  Weitere Fragen
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography variant="subtitle1">
                      Ist das Gerät bereits geöffnet worden?{" "}
                      {order.opened_before ? "Ja" : "Nein"}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      Erlitt das Gerät einen Flüssigkeitsschaden?{" "}
                      {order.fluid_damage ? "Ja" : "Nein"}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      Soll der Akku ersetzt werden?{" "}
                      {order.replace_battery ? "Ja" : "Nein"}
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" style={{}}>
                  Wie schnell soll es gehen?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  {getDileveryTime(order.repair_time_type)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </form>
        )}
        <ImageViewer
          open={openImageViewer}
          src={imageSrc}
          handleClose={() => {
            setOpenImageViewer(false);
          }}
        />
      </DialogContent>
      <DialogActions className="actionButtons"></DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  brands: state.orders.brands,
});

export default connect(mapStateToProps, {})(ViewCustomer);
