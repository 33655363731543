import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const allProblems = [
    'Glasbruch' , 'Touchscreen' , 'LCD Display' ,
    'Lautsprecher' , 'Audio Jack' , 'Mikrofon', 'Backcover', 'Software',
    'Ladeanschluss' , 'Eis/Aus Schalter' , 'Lautstärketaste',
    'Home Button' , 'Frontkamera' , 'Backkamera',
    'Vibration' , 'Wifi' , 'Bluetooth',
    'Netzempfang' , 'Lichtsensor' , 'Wasserschaden',
    'Akku' , 'Rahmen',
];
export default function Step2(props) {
    const handleChange = (name , value) => {
        let newProblems = {...props.problems};
        if(value){
            newProblems[name] = value;
        }else{
            delete newProblems[name]
        }
        props.update('problems' , newProblems);
    };
    return (
        <div className="steps">
            <form>
                <Grid container spacing={2}>
                    {allProblems.map((problem,index) => (
                        <Grid container item xs={6} md={4} key={"problem_"+index}>
                            <FormControlLabel
                                label={problem}
                                control={<Checkbox name={problem} color="primary"
                                checked={props.problems[problem] !== undefined}
                                onChange={(event) => {handleChange(problem , event.target.checked)}} />}
                            />
                        </Grid>
                    ))}
                </Grid>
                <div className="addComment">
                    <h5 className="center"> Falls nicht aufgelistet </h5>
                    <TextareaAutosize aria-label="minimum height"
                        rowsMin={3} placeholder="Beschreiben Sie den Fehler"
                        value={props.problems['extra'] ? props.problems['extra'] : ""}
                        onChange={(event) => {
                            handleChange('extra' , event.target.value)
                        }}
                    />
                </div>
            </form>
        </div>
    );
}
