import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import isEmpty from '../../utils/isEmpty';


export default function Step3(props) {
    const handleChange = (key , value) => {
        props.update(key , value);
    };
    return (
        <div className="steps step3">
            <form>
                <Grid container spacing={0}>
                    <Grid container item xs={8} md={9}>
                    </Grid>

                    <Grid container item xs={4} md={3}>
                        <span> Ja </span>  <span> Nein </span>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid container item xs={8} md={9}>
                        <p> Ist das Gerät bereits geöffnet worden? </p>
                    </Grid>
                    <Grid container item xs={4} md={3}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('opened_before' , true)} checked={props.opened_before} />}  />
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('opened_before' , false)} checked={!isEmpty(props.opened_before) && !props.opened_before} />}  />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid container item xs={8} md={9}>
                        <p> Erlitt das Gerät einen Flüssigkeitsschaden? </p>
                    </Grid>
                    <Grid container item xs={4} md={3}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('fluid_damage' , true)} checked={props.fluid_damage} />}  />
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('fluid_damage' , false)} checked={!isEmpty(props.fluid_damage) && !props.fluid_damage} />}  />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid container item xs={8} md={9}>
                        <p> Soll der Akku ersetzt werden? </p>
                    </Grid>
                    <Grid container item xs={4} md={3}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('replace_battery' , true)} checked={props.replace_battery} />}  />
                            <FormControlLabel control={<Radio color="primary" onChange={() => handleChange('replace_battery' , false)} checked={!isEmpty(props.replace_battery) && !props.replace_battery} />}  />
                        </RadioGroup>
                    </Grid>
                </Grid>

            </form>
        </div>
    );
}
