import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import deleteIcon from "./../../img/delete.png";

function SparePart(props) {
  const { item } = props;
  return (
    <Grid
      container
      spacing={2}
      key={"spare_parts_" + item._id}
      className="spareCards"
    >
      <Grid item xs={6} md={6} lg={3}>
        <h5> Marke: </h5>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h6> {item.brand}</h6>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h5> Modell: </h5>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h6> {item.model} </h6>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h5> Ersatzteil: </h5>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h6> {item.parts} </h6>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h5> Stückzahl:</h5>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <h6> {item.number} </h6>
      </Grid>
      <a
        onClick={() => {
          props.delete(item._id);
        }}
        className="deleatButton"
      >
        <img src={deleteIcon} style={{ width: 20, height: 20 }} />
      </a>
    </Grid>
  );
}

export default SparePart;
