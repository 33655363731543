import React from "react";

import Grid from "@material-ui/core/Grid";
import edit from "./../../img/edit.png";
import add from "./../../img/add.png";
import plus from "./../../img/plus.png";
import info from "./../../img/info.png";
import deleteIcon from "./../../img/delete.png";

import ConfirmationDialog from "../../components/ConfirmationDialog";

import { setOrderToDone, deletePart } from "../../actions/orderActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PrintOrderID from "./PrintOrderID";

function SpareParts(props) {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { orders, handleOpenEdit, handleOpenSpare, handleClose } = props;
  const history = useHistory();

  useEffect(() => {
    if (props.messages && props.messages.partDeleted) {
      history.go();
    }
  }, [props.messages]);
  const getDileveryTime = (option) => {
    if (option == 0) {
      return "Sameday Reparatur";
    } else if (option == 1) {
      return "Standard";
    } else if (option == 2) {
      return "Sofort Reparatur";
    }
    return "";
  };

  const getTechnicianName = (id) => {
    const technician = props.technicians.find(
      (technician) => technician._id == id
    );
    return technician ? technician.adminName : "";
  };
  return (
    <div>
      {orders.length <= 0 && <h4> Keine Aufträge </h4>}
      {orders.map((order) => {
        return (
          <div key={"spare_parts_" + order._id}>
            <div className="jobBox spareJobBox">
              <h5 className="center">
                {order.deliveryDate
                  ? moment(order.deliveryDate).format("DD-MM-YYYY HH:mm")
                  : "N/A"}
              </h5>
              <ul>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <div>
                        <h5> Bestellcode: </h5>
                      </div>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6> {order.code} </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <div>
                        <h5> Kundenname: </h5>
                      </div>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6> {order.customer && order.customer.customerName} </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Erstellungsdatum: </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6>
                        {" "}
                        {moment(order.createdAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}{" "}
                      </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Lieferdatum: </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6>
                        {" "}
                        {order.deliveryDate
                          ? moment(order.deliveryDate).format(
                              "DD-MM-YYYY HH:mm"
                            )
                          : "N/A"}{" "}
                      </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Lieferzeit: </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6> {getDileveryTime(order.repair_time_type)} </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Preis (brutto): </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6> {order.price ? order.price : "N/A"} </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Notizen: </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6> {order.notice ? order.notice : "N/A"} </h6>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Grid container spacing={1}>
                    <Grid container item xs={6} md={4}>
                      <h5> Eingeteilte Person: </h5>
                    </Grid>
                    <Grid container item xs={6} md={8}>
                      <h6>
                        {order.technician ? order.technician.adminName : "N/A"}
                      </h6>
                    </Grid>
                  </Grid>
                </li>
              </ul>
              <p>Ersatzteile: </p>
              <ul className="spareList">
                {order &&
                  order.spareParts &&
                  order.spareParts.length > 0 &&
                  order.spareParts.map((sparePart) => (
                    <li key={"spare_parts_" + sparePart._id}>
                      <Grid container spacing={1}>
                        <Grid container item xs={7} md={6} lg={8}>
                          <div> {sparePart.parts} </div>
                        </Grid>
                        <Grid container item xs={3} md={4} lg={2}>
                          <h6> {sparePart.number} </h6>
                        </Grid>
                        <Grid container item xs={2} md={2} lg={2}>
                          <a
                            onClick={() => {
                              props.deletePart(sparePart._id);
                            }}
                          >
                            <img src={deleteIcon} />
                          </a>
                        </Grid>
                      </Grid>
                    </li>
                  ))}

                <li className="listButtons">
                  <a
                    onClick={() => {
                      handleOpenEdit(order);
                    }}
                  >
                    <img src={edit} />
                  </a>
                  {order.status != 3 && (
                    <React.Fragment>
                      <ConfirmationDialog
                        open={confirmationOpen}
                        title="Bestätigung"
                        body="Möchten Sie diesen Job als erledigt markieren?"
                        confirm="Bestätigen"
                        cancel="Abbrechen"
                        handleClose={() => {
                          setConfirmationOpen(false);
                        }}
                        handleSuccess={() => {
                          setConfirmationOpen(false);
                          props.setOrderToDone(order._id);
                        }}
                      />
                      <a
                        onClick={() => {
                          setConfirmationOpen(true);
                        }}
                      >
                        <img src={add} />
                      </a>
                    </React.Fragment>
                  )}
                  <a
                    onClick={() => {
                      handleOpenSpare(order);
                    }}
                  >
                    <img src={plus} />
                  </a>
                  <a
                    onClick={() => {
                      props.handleOpenInfo(order);
                    }}
                  >
                    <img src={info} />
                  </a>
                  <PrintOrderID orderID={order.code} />
                </li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}
const mapStateToProps = (state) => ({
  messages: state.messages,
  technicians: state.admins.technicians,
});
export default connect(mapStateToProps, {
  setOrderToDone,
  deletePart,
})(SpareParts);
