import axios from "axios";
import { GET_MESSAGES, SET_ADMIN, SET_TECHNICIANS } from "./types";

export const addAdmin = (data) => (dispatch) => {
  axios
    .post("/api/admins/register", data)
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_MESSAGES,
        payload: err.response.data,
      })
    );
};

export const adminLogin = (data) => (dispatch) => {
  axios
    .post("/api/admins/login", data)
    .then((res) => {
      if (res.data.token) {
        localStorage.setItem("admin_token", res.data.token);
      }
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
    })
    .catch((err) => {
      const data = err.response.data;
      if (data) {
        dispatch({
          type: GET_MESSAGES,
          payload: data,
        });
      }
    });
};

export const getLoggedAdmin = (data) => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .get("/api/admins/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data.messages,
      });
      dispatch({
        type: SET_ADMIN,
        payload: res.data.admin,
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        dispatch({
          type: GET_MESSAGES,
          payload: err.response.data,
        });
      }
    });
};

export const getTechnicians = () => (dispatch) => {
  const token = localStorage.getItem("admin_token");
  axios
    .get("/api/admins/technicians", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
      dispatch({
        type: SET_TECHNICIANS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        dispatch({
          type: GET_MESSAGES,
          payload: err.response.data,
        });
      }
    });
};

export const resetAdminPassword = (data) => (dispatch) => {
  axios
    .post("/api/admins/forget-password", data)
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        dispatch({
          type: GET_MESSAGES,
          payload: err.response.data,
        });
      }
    });
};
