import React from "react";
import ReactToPrint from "react-to-print";
import print from "./../../img/print.png";

class ComponentToPrint extends React.Component {
  render() {
    const { code, customer, mobileNumber, technician } = this.props.order;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
          width: "100vw",
        }}
        className="print"
      >
        <p style={{ fontSize: 140 }}>{code}</p>
        {customer && <p style={{ fontSize: 140 }}>{customer.customerName}</p>}
        {customer && (
          <p style={{ fontSize: 140 }}>
            {mobileNumber ? mobileNumber : customer.customerNumber}
          </p>
        )}
        {technician && <p style={{ fontSize: 140 }}>{technician.adminName}</p>}
      </div>
    );
  }
}

class PrintOrderID extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <a href="javascript:void(0)">
              <img src={print} />
            </a>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={(el) => (this.componentRef = el)}
            order={this.props.order}
          />
        </div>
      </div>
    );
  }
}

export default PrintOrderID;
