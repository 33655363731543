import {
  GET_CURRENT_CUSTOMER,
  SET_DEPENDENT_REGISTER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  SET_CUSTOMER,
} from "../actions/types";
const initialState = {
  customer: {},
  customers: [],
  registered: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case SET_DEPENDENT_REGISTER:
      return {
        ...state,
        registered: true,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
        registered: false,
      };
    default:
      return state;
  }
}
