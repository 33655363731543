import React, { Component, useState, useEffect } from "react";
import { getLoggedAdmin, getTechnicians } from "../../actions/adminActions";
import { getCustomers } from "../../actions/customerActions";
import {
  getOrders,
  getBrands,
  createOrUpdateOrder,
  syncOrder,
} from "../../actions/orderActions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import SpareParts from "../../components/admin/SpareParts";
import EditJob from "../../components/admin/EditJob";
import AddSpareParts from "../../components/admin/AddSpareParts";
import Orders from "../../components/admin/Orders";
import ViewInfo from "../../components/admin/ViewInfo";

import io from "socket.io-client";
import isEmpty from "../../utils/isEmpty";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function AdminHome(props) {
  const [messages, setMessages] = useState("");
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  const [filterTechnician, setFilterTechnician] = useState("");
  const [filterBrand, setfilterBrand] = useState("");

  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedParts, setSelectedParts] = useState({});
  const [selectedOrderInfo, setSelectedOrderInfo] = useState({});

  const [openEdit, setOpenEdit] = useState(false);
  const [openSparePart, setOpenSparePart] = useState(false);
  const [openViewInfo, setOpenViewInfo] = useState(false);

  let history = useHistory();
  useEffect(() => {
    props.getLoggedAdmin();
    props.getTechnicians();
    props.getCustomers();
    props.getBrands();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.admin)) {
      connectToSocket();
    }
  }, [props.admin]);

  const connectToSocket = () => {
    try {
      const socket = io.connect();
      socket.on("update", (data) => {
        if (props.admin) {
          const { updated_order, updated_by } = data;
          if (updated_by._id != props.admin._id) {
            props.syncOrder(updated_order);
          }
        }
      });
      socket.on("create", (data) => {
        if (props.admin) {
          const { new_order } = data;
          props.createOrUpdateOrder(new_order);
        }
      });
    } catch (err) {
      console.log("connection error", err);
    }
  };

  useEffect(() => {
    setMessages(props.messages);
    if (props.messages && props.messages.adminMeError) {
      localStorage.removeItem("admin_token");
      history.push("/");
    } else if (props.messages && props.messages.adminMeSucess) {
      props.getOrders();
    }
  }, [props.messages]);

  useEffect(() => {
    setOrders(props.orders);
    setAllOrders(props.orders);
  }, [props.orders]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenEdit = (order) => {
    setSelectedParts({});
    setOpenSparePart(false);
    setSelectedEdit(order);
    setOpenEdit(true);
  };

  const handleClose = (order) => {
    setSelectedParts({});
    setOpenSparePart(false);
    setSelectedEdit({});
    setOpenEdit(false);
  };

  const handleOpenSpare = (order) => {
    setOpenEdit(false);
    setSelectedEdit({});
    setSelectedParts(order);
    setOpenSparePart(true);
  };

  const handleOpenInfo = (order) => {
    setSelectedOrderInfo(order);
    setOpenViewInfo(true);
  };

  const handleCloseViewInfo = () => {
    setSelectedOrderInfo({});
    setOpenViewInfo(false);
  };

  useEffect(() => {
    let ordersClone = [...allOrders];
    if (ordersClone && ordersClone.length > 0) {
      if (filterTechnician) {
        ordersClone = ordersClone.filter((orderClone) => {
          if (orderClone.technician) {
            return orderClone.technician.adminName
              .toLowerCase()
              .includes(filterTechnician.toLowerCase());
          } else return false;
        });
      }
      if (filterBrand) {
        ordersClone = ordersClone.filter((orderClone) => {
          return orderClone.device.toLowerCase() === filterBrand.toLowerCase();
        });
      }
      setOrders(ordersClone);
    } else {
      setOrders(allOrders);
    }
  }, [filterBrand, filterTechnician, allOrders]);

  return (
    <div className="AdminHome">
      <AppBar position="static" color="default">
        <div>
          <TextField
            fullWidth
            placeholder="Filtern nach Mitarbeiter"
            name="query"
            variant="outlined"
            style={{ marginBottom: 20 }}
            onChange={(event) => {
              setFilterTechnician(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Autocomplete
            options={props.brands}
            getOptionLabel={(option) => option.brand}
            onChange={(event, value) => {
              let query = value ? value.code : "";
              setfilterBrand(query);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gerät"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="offen" {...a11yProps(0)} />
          <Tab label="Benötigt Ersatzteile" {...a11yProps(1)} />
          <Tab label="Platinenreperatur" {...a11yProps(2)} />
          <Tab label="Standardreperatur" {...a11yProps(3)} />
          <Tab label="Reperatur abgeschlossen" {...a11yProps(4)} />
          <Tab label="Abgeschlossen" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Orders
          orders={orders.filter((order) => {
            return order.status == 0;
          })}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Orders
          orders={orders.filter((order) => {
            return order.status == 1;
          })}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Orders
          orders={orders.filter((order) => {
            return order.status == 2;
          })}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleClose={handleClose}
          handleOpenInfo={handleOpenInfo}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Orders
          orders={orders.filter((order) => order.status == 3)}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleOpenInfo={handleOpenInfo}
          handleClose={handleClose}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Orders
          orders={orders.filter((order) => order.status == 4)}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleOpenInfo={handleOpenInfo}
          handleClose={handleClose}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Orders
          orders={orders.filter((order) => order.status == 5)}
          handleOpenEdit={handleOpenEdit}
          handleOpenSpare={handleOpenSpare}
          handleOpenInfo={handleOpenInfo}
          handleClose={handleClose}
        />
      </TabPanel>
      {selectedEdit && (
        <EditJob
          handleClose={() => {
            setOpenEdit(false);
          }}
          open={openEdit}
          order={selectedEdit}
        />
      )}
      {openSparePart && (
        <AddSpareParts
          handleClose={() => {
            setOpenSparePart(false);
          }}
          open={openSparePart}
          order={selectedParts}
        />
      )}
      {openViewInfo && (
        <ViewInfo
          open={openViewInfo}
          order={selectedOrderInfo}
          handleClose={handleCloseViewInfo}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  admin: state.admins.admin,
  orders: state.orders.orders,
  brands: state.orders.brands,
});

export default connect(mapStateToProps, {
  getLoggedAdmin,
  getOrders,
  getTechnicians,
  getCustomers,
  getBrands,
  createOrUpdateOrder,
  syncOrder,
})(AdminHome);
