import {
  GET_ORDERS,
  GET_CURRENT_ORDER,
  SET_ORDER_TO_DONE,
  DELETE_ORDER,
  UPDATE_ORDER,
  GET_BRANDS,
  CREATE_OR_UPDATE_ORDER,
  REMOVE_ORDER,
} from "../actions/types";
const initialState = {
  orders: [],
  brands: [],
  currentOrder: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };

    case GET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
      };
    case SET_ORDER_TO_DONE:
      const index = state.orders.findIndex((order) => {
        return order._id == action.payload;
      });
      const clone = [...state.orders];
      if (index !== -1) {
        clone[index].status = 3;
      }
      return {
        ...state,
        orders: clone,
      };
    case UPDATE_ORDER:
      const updateIndex = state.orders.findIndex((order) => {
        return order._id == action.payload._id;
      });
      const updateClone = [...state.orders];
      if (updateIndex !== -1) {
        updateClone[updateIndex] = action.payload;
      }
      return {
        ...state,
        orders: updateClone,
      };
    case CREATE_OR_UPDATE_ORDER:
      const tempIndex = state.orders.findIndex((order) => {
        return order._id == action.payload._id;
      });
      const OrderTempClone = [...state.orders];
      if (tempIndex !== -1) {
        OrderTempClone[tempIndex] = action.payload;
      } else {
        OrderTempClone.push(action.payload);
      }
      return {
        ...state,
        orders: OrderTempClone,
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order._id !== action.payload),
      };
    case REMOVE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order._id !== action.payload),
      };
    default:
      return state;
  }
}
