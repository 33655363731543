import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

function Step5(props) {
  const [width, setWidth] = useState(null);
  const [open, setOpen] = React.useState(false);

  const sigPad = useRef(null);

  const handleChange = (event) => {
    props.update(event.target.name, event.target.value);
  };

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleCheckbox = (event) => {
    props.update(event.target.name, event.target.checked);
  };

  const [SignutureWidth, setSignutureWidth] = useState([]);
  const stageCanvasRef = useRef(null);

  useEffect(() => {
    if (stageCanvasRef.current) {
      let width = stageCanvasRef.current.offsetWidth;
      setSignutureWidth(width);
    }
  }, [stageCanvasRef]);

  const getDileveryTime = (option) => {
    if (option == 0) {
      return "Sameday Reparatur";
    } else if (option == 1) {
      return "Standard";
    } else if (option == 2) {
      return "Sofort Reparatur";
    }
    return "";
  };

  const getProblems = (problems) => {
    if (problems) {
      try {
        problems = Object.keys(problems).map((problem) => {
          if (problem == "extra") {
            return problems["extra"];
          }
          return problem;
        });
        return problems.join(" , ");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getBrand = (code) => {
    const brand = props.brands.find((brand) => brand.code == code);
    return brand.brand;
  };
  console.log(props);
  return (
    <div className="steps step3">
      <form>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" style={{}}>
              Geräte-Informationen
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Gerät: {getBrand(props.device)}
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle1">
                  Modell: {props.model}
                </Typography>
              </li>

              {props.keyCode_type == 0 && (
                <li>
                  <Typography variant="subtitle1">
                    Tastensperrcode: {props.keyCode}
                  </Typography>
                </li>
              )}

              {props.keyCode_type == 1 && (
                <li>
                  <Typography variant="subtitle1">
                    Tastensperrcode:
                    <a
                      href="#"
                      onClick={() => {
                        var newTab = window.open();
                        newTab.document.body.innerHTML =
                          '<img src="' +
                          props.keyCode +
                          '" width="100px" height="100px">';
                        return false;
                      }}
                    >
                      View
                    </a>
                  </Typography>
                </li>
              )}
              <li>
                <Typography variant="subtitle1">
                  Telefonnummer: {props.mobileNumber}
                </Typography>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" style={{}}>
              Fehler
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1">
              Problem: {getProblems(props.problems)}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" style={{}}>
              Weitere Fragen
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Ist das Gerät bereits geöffnet worden?{" "}
                  {props.opened_before ? "Ja" : "Nein"}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Erlitt das Gerät einen Flüssigkeitsschaden?{" "}
                  {props.fluid_damage ? "Ja" : "Nein"}
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Soll der Akku ersetzt werden?{" "}
                  {props.replace_battery ? "Ja" : "Nein"}
                </Typography>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" style={{}}>
              Wie schnell soll es gehen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1">
              {getDileveryTime(props.repair_time_type)}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="instruction_agreement"
                color="primary"
                checked={props.instruction_agreement}
                onChange={handleCheckbox}
              />
            }
          />
          <p>Ich habe die
            <a onClick={handleClickOpen} href="#">
              {" "}
              AGBs{" "}
          </a> gelesen und akzeptiere diese.</p>
        </div>
        <h5> Unterschrift </h5>
        <section className="signuture" ref={stageCanvasRef}>
          <SignatureCanvas
            penColor="black"
            ref={sigPad}
            onEnd={(data) => {
              props.update(
                "signature",
                sigPad.current.getTrimmedCanvas().toDataURL("image/png")
              );
            }}
            canvasProps={{
              width: SignutureWidth,
              height: 200,
              className: "sigCanvas",
            }}
          />
        </section>
        <Button
          disabled={!props.signature}
          onClick={() => {
            sigPad.current.clear();
            props.update("signature", undefined);
          }}
        >
          Unterschrift löschen
        </Button>
      </form>
      <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          Allgemeine Geschäftsbedingungen
          {" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
              id="alert-dialog-description"
              component={"span"}
              variant={"body2"}
          >
            Nach genauer Prüfung werden Sie persönlich kontaktiert und erfahren die exakten Reparaturkosten. Erst nach ihrer Zusage beginnt die Reparatur. Falls sie sich gegen eine Reparatur entscheiden, wird eine Servicepauschale in Höhe von 19,- Euro verrechnet. Wurde das Gerät vorher schon Mal geöffnet bzw. kam es mit Flüssigkeit in Berührung, übernehmen wir für unseren Reparaturversuch keine Garantie oder Haftung! Für Platinen Reparatur und Datenverlust  geben wir keine Garantie oder übernehmen keine Haftung. Jede Verantwortlichkeit und die Haftung für Folgeschäden sowie auch für reine Vermögensschäden und mittelbare Schäden ist ausgeschlossen.
            Mit Ihrer Unterschrift beauftragen Sie Gemini Gp e.U Schönaugasse 23, 8010 Graz, in Ihrem Namen, eine Reparatur vorzunehmen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
              onClick={() => {
                props.update("agreement", true);
                setOpen(false);
              }}
              color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  brands: state.orders.brands,
});

export default connect(mapStateToProps, {})(Step5);
