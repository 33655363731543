import {
  GET_CURRENT_ADMIN,
  GET_ADMINS,
  SET_ADMIN,
  DELETE_ADMIN,
  SET_TECHNICIANS,
} from "../actions/types";
const initialState = {
  admin: {},
  technicians: [],
  registered: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case SET_TECHNICIANS:
      return {
        ...state,
        technicians: action.payload,
      };
    case SET_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case DELETE_ADMIN:
      return {
        ...state,
        admin: action.payload,
        registered: false,
      };
    default:
      return state;
  }
}
