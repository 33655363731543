import React, { useEffect, Fragment, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import edit from "./../../img/edit.png";
import add from "./../../img/add.png";
import plus from "./../../img/plus.png";
import info from "./../../img/info.png";
import moment from "moment";
import { connect } from "react-redux";
import { setOrderToDone, deletePart } from "../../actions/orderActions";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PrintOrderID from "./PrintOrderID";
import SparePart from "./SparePart";
import { useHistory } from "react-router-dom";

function OrderTechnician(props) {
  const { order } = props;
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [openSparePart, setOpenSparePart] = useState(false);

  const [
    deleteSpareConfirmationOpen,
    setDeleteSpareConfirmationOpen,
  ] = useState(false);
  const [deleteSpareID, setDeleteSpareID] = useState(null);

  const printElementRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (props.messages && props.messages.partDeleted) {
      history.go();
    }
  }, [props.messages]);
  const handleClickOpen = () => {
    props.handleOpenEdit(order);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const getDileveryTime = (option) => {
    if (option == 0) {
      return "Sameday Reparatur";
    } else if (option == 1) {
      return "Standard";
    } else if (option == 2) {
      return "Sofort Reparatur";
    }
    return "";
  };

  const getBrand = (code) => {
    if (props.brands && props.brands.length > 0) {
      const brand = props.brands.find((brand) => brand.code == code);
      return brand ? brand.brand : "";
    }
  };

  return (
    <div>
      <div className="jobBox" key={order.id}>
        <ul>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <div>
                  <h5> Bestellcode: </h5>
                </div>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {order.code} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <div>
                  <h5> Marke: </h5>
                </div>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {getBrand(order.device)} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <div>
                  <h5> Modell: </h5>
                </div>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {order.model} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <div>
                  <h5> Kundenname: </h5>
                </div>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {order.customer && order.customer.customerName} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Erstellungsdatum: </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {moment(order.createdAt).format("DD-MM-YYYY HH:mm")} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Lieferdatum: </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6>
                  {order.deliveryDate
                    ? moment(order.deliveryDate).format("DD-MM-YYYY HH:mm")
                    : "N/A"}{" "}
                </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Lieferzeit: </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {getDileveryTime(order.repair_time_type)} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Preis (brutto): </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {order.price ? order.price : "N/A"} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Notizen: </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6> {order.notice ? order.notice : "N/A"} </h6>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1}>
              <Grid container item xs={6} md={4}>
                <h5> Eingeteilte Person: </h5>
              </Grid>
              <Grid container item xs={6} md={8}>
                <h6>{order.technician ? order.technician.adminName : "N/A"}</h6>
              </Grid>
            </Grid>
          </li>
        </ul>
        {order && order.spareParts && order.spareParts.length > 0 && (
          <div>
            <p>Ersatzteile: </p>
            {order.spareParts.map((sparePart) => (
              <SparePart
                item={sparePart}
                delete={(id) => {
                  setDeleteSpareID(id);
                  setDeleteSpareConfirmationOpen(true);
                }}
              />
            ))}
          </div>
        )}
        <div>
          <ul>
            <li className="listButtons">
              <a onClick={handleClickOpen}>
                <img src={edit} />
              </a>
              {order.technicianStatus != 2 && (
                <Fragment>
                  <ConfirmationDialog
                    open={confirmationOpen}
                    title="Bestätigung"
                    body="Möchten Sie diesen Job als erledigt markieren?"
                    confirm="Bestätigen"
                    cancel="Abbrechen"
                    handleClose={() => {
                      setConfirmationOpen(false);
                    }}
                    handleSuccess={() => {
                      setConfirmationOpen(false);
                      props.setOrderToDone(order._id);
                    }}
                  />
                  <a
                    onClick={() => {
                      setConfirmationOpen(true);
                    }}
                  >
                    <img src={add} />
                  </a>
                </Fragment>
              )}
              <a
                onClick={() => {
                  props.handleOpenSpare(order);
                }}
              >
                <img src={plus} />
              </a>
              <a
                onClick={() => {
                  props.handleOpenInfo(order);
                }}
              >
                <img src={info} />
              </a>
              <PrintOrderID order={order} />
            </li>
          </ul>
        </div>
      </div>
      <ConfirmationDialog
        open={deleteSpareConfirmationOpen}
        title="Bestätigung"
        body="Möchten Sie dies wirklich löschen?"
        confirm="Bestätigen"
        cancel="Abbrechen"
        handleClose={() => {
          setDeleteSpareID(null);
          setDeleteSpareConfirmationOpen(false);
        }}
        handleSuccess={() => {
          setDeleteSpareConfirmationOpen(false);
          props.deletePart(deleteSpareID);
          setDeleteSpareID(null);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  technicians: state.admins.technicians,
  brands: state.orders.brands,
});
export default connect(mapStateToProps, {
  setOrderToDone,
  deletePart,
})(OrderTechnician);
